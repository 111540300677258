import React from "react"
import { graphql } from 'gatsby'
import Head from "../components/Head"
import '../styles/index.css'

const AboutPage = () => {
  return <Head title="About" />
}

export const query = graphql`
query {
  prismic {
    allAbouts {
      edges {
        node {
          email
          call
          address
          about
          collapsed
          logos
        }
      }
    }
    allFooters {
      edges {
        node {
          instagram
          linkedin
          newsletter
        }
      }
    }
  }
}
`

export default AboutPage
